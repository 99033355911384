import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logoGomex from "./images/logo-gomex.png";
import { Link } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link to="/" style={{ textDecoration: "none", color: "#039BE5" }}>
        Nikola Gavrilovic
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function TheFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800]
      }}
    >
      <Container maxWidth="lg">
        <Link to="/">
          <img src={logoGomex} style={{ width: "150px" }} alt="gomex-logo" />
        </Link>
        <Copyright />
      </Container>
    </Box>
  );
}
