import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import Brijaci from "./Brijaci";
import Pene from "./Pene";
import Kategorije from "./Kategorije";
import ProductDetails from "./ProductDetails";

let theme = createTheme({
  palette: {
    primary: {
      main: "#0052cc"
    },
    secondary: {
      main: "#edf2ff"
    }
  }
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/" element={<Kategorije />} />
      <Route path="/brijaci" element={<Brijaci />} />
      <Route path="brijaci/:name" element={<ProductDetails />} />
      <Route path="/kozmetika" element={<Pene />} />
      <Route path="/kozmetika/:name" element={<ProductDetails />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
