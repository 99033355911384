import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import brijaci from "./images/kategorija-brijaci.png";
import pene from "./images/kategorija-pene.png";

function Kategorije() {
  const navigate = useNavigate();
  const categories = useMemo(
    () => [
      {
        id: 1,
        name: "Brijači",
        photo: brijaci,
        href: "/brijaci"
      },
      {
        id: 2,
        name: "Kozmetika",
        photo: pene,
        href: "/kozmetika"
      }
    ],
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      {categories.map((category) => (
        <Card
          sx={{ maxWidth: 345, marginRight: isMobile ? "20px" : "35px" }}
          onClick={() => navigate(category.href)}
          key={category.id}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height={isMobile ? "100%" : "240px"}
              image={category.photo}
              alt={category.name}
              sx={{ objectFit: "contain" }}
            />
            <CardContent sx={{ backgroundColor: "#f1f2f63b" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: isMobile ? "16px" : "17px",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "#2f3542"
                }}
              >
                {category.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
}

export default Kategorije;
