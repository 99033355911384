import { useMemo } from "react";
import { Box } from "@mui/system";
import pic15 from "./images/Gillette AS Lot 100ml Citrus Fizz.jpeg";
import pic16 from "./images/GILLETTE 654 FUSION SENSITIVE GEL 200ML.jpeg";
import pic17 from "./images/Gillette Series Revitalising Gel 200 ml.jpeg";
import pic18 from "./images/Gillette Series Revitalising Foam 250 ml.jpeg";
import pic19 from "./images/Gillette Anti-perspirant Spray Cool Wave.png";
import pic20 from "./images/Gillette A-P Power Beads 75ml Power Rush.jpeg";
import { useMediaQuery, useTheme } from "@mui/material";
import AppCard from "./Card";

function Pene() {
  const products = useMemo(
    () => [
      {
        id: 15,
        name: "Gillette AS Lot 100ml Citrus Fizz",
        photo: pic15,
        price: 1009.99,
        description:
          "Gillette Energizing losion posle brijanja je specijalno dizajniran za negu muške kože. Revitalizuje sveže obrijanu kožu. Završava brijanje ostavljajuc´i osec´aj svežine i čistoc´e.",
        children: []
      },
      {
        id: 16,
        name: "GILLETTE 654 FUSION SENSITIVE GEL 200ML",
        photo: pic16,
        price: 649.99,
        description:
          "Gel za brijanje za muškarce za neverovatnu udobnost kože. Gillette Fusion5 Ultra Sensitive gel za brijanje za muškarce ima Ultra Comfort kompleks koji hladi i raščešljava i pomaže u zaštiti kože tokom brijanja tako da se oseća čisto i udobno. Ovaj gel za brijanje je pogodan za osetljivu kožu i nudi neverovatnu udobnost brijanja. Koristite sa brijačom Fusion5 za udobnost pre, tokom i posle brijanja.",
        children: []
      },
      {
        id: 17,
        name: "Gillette Series Revitalising Gel 200 ml (654)",
        photo: pic17,
        price: 549.99,
        description:
          "Gillette Series Revitalizing gel za brijanje za neverovatno klizanje brijača. Pruža zaštitu od crvenila i smanjuje osećaj zategnutosti kože.",
        children: []
      },
      {
        id: 18,
        name: "Gillette Series Revitalising Foam 250 ml (654)",
        photo: pic18,
        price: 429.99,
        description:
          "Gillette Series Revitalizing Care formula za trostruku zaštitu koristi tri različita aktivna sastojka za povec´anje sposobnosti klizanja brijača i zaštitu vaše kože od posekotina, crvenila i napetosti. Bogata formula pruža neverovatno glatko i udobno brijanje. Gillette Series pena je Revitalizujuc´a nega sa dodatnom porcijom hidratantnih krema i brzo prodire u kožu i omekšava dlake brade.",
        children: []
      },
      {
        id: 19,
        name: "Gillette Anti-perspirant Spray Cool Wave",
        photo: pic19,
        price: 399.99,
        description:
          "Gillette Anti-perspirant Spray Cool Wave je antiperspirant koji vam pruža snažnu 48-časovnu zaštitu od znojenja. Ima precizno dizajniranu formulu koja vas održava svežima ceo dan. Osim toga, Gillette Clear Gel postaje providan i ostaje providan - pružajući vam nevidljivu zaštitu od znoja. Na kraju krajeva, ovo je najbolje što čovek može da dobije.",
        children: []
      },
      {
        id: 20,
        name: "Gillette A-P Power Beads 75ml Power Rush",
        photo: pic20,
        price: 379.99,
        description:
          "Gillette Power Rush Clear gel dezodorans predstavlja proizvod iz Gillette linije proizvoda za mušku negu koji vam pruža zaštitu tokom 48 sati i eleminiše neugodan miris. Ne prikrivajte neugodan miris, neutrališite ga!",
        children: []
      }
    ],
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ marginTop: "10px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "flex-start"
          }}
        >
          {products.map((product) => (
            <AppCard product={product} key={product.id} />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default Pene;
