import { Box, Container } from "@mui/material";
import TheFooter from "./TheFooter";
import TheHeader from "./TheHeader";
import { Outlet } from "react-router-dom";
import AppBreadcrumbs from "./BreadCrumbs";

function App() {
  return (
    <>
      <TheHeader />
      <Container
        maxWidth="lg"
        sx={{ marginBottom: "70px", paddingTop: "50px", minHeight: "100vh" }}
      >
        <Box sx={{ marginBottom: "30px" }}>
          <AppBreadcrumbs />
        </Box>
        <Outlet />
      </Container>
      <TheFooter />
    </>
  );
}

export default App;
