import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  CardActions,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface IProduct {
  id: number;
  name: string;
  photo: string;
  description: string;
  price: number;
  children?: {
    id: number;
    name: string;
    photo: string;
    price: number;
    description: string;
  }[];
}

export default function AppCard({ product }: { product: IProduct }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        maxWidth: isMobile ? "161px" : "270px",
        marginRight: "10px",
        marginBottom: "10px"
      }}
    >
      <CardActionArea
        onClick={() =>
          navigate(`${pathname}/${product.name}`, { state: product })
        }
      >
        <CardMedia
          component="img"
          height={isMobile ? "155px" : "220px"}
          image={product.photo}
          alt={product.name}
          sx={{ objectFit: "contain" }}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            sx={{
              fontSize: isMobile ? "13px" : "15px",
              fontWeight: "600",
              textAlign: "center",
              color: "#2f3542"
            }}
          >
            {product.name}
          </Typography>
          {product.description && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: isMobile ? "10px" : "14px",
                letterSpacing: "0px"
              }}
            >
              {product.description.slice(0, 89)}
              <span
                style={{
                  color: "#039BE5",
                  fontWeight: "500"
                }}
              >
                ...saznaj vise
              </span>
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <span
          style={{ marginLeft: "auto", color: "#66BB6A", fontWeight: "600" }}
        >
          {product.price?.toFixed(2)} RSD
        </span>
      </CardActions>
    </Card>
  );
}
