import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ProductDetails() {
  const { state } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function renderChildren() {
    if (state?.children?.length > 0) {
      return (
        <div style={{ marginTop: "20px" }}>
          <hr
            style={{
              borderTop: "0.5px solid rgb(206 209 210)",
              borderBottom: 0
            }}
          />
          <h3
            style={{
              fontSize: "18px",
              margin: "20px 0"
            }}
          >
            Povezani proizvodi
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            {state?.children?.map((item: any) => (
              <div key={item?.id} style={{ marginRight: "30px" }}>
                <img
                  src={item?.photo}
                  alt={item?.name}
                  style={{
                    maxWidth: "100%",
                    width: isMobile ? "100%" : "180px",
                    objectFit: "contain"
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "#2f3542"
                  }}
                >
                  {item?.name}
                </Typography>
                <span
                  style={{
                    color: "#66BB6A",
                    fontWeight: "600"
                  }}
                >
                  {item?.price?.toFixed(2)} RSD
                </span>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: "15px"
                  }}
                >
                  {item?.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <div>
          <img
            src={state?.photo}
            alt={state?.name}
            style={{ width: "300px", objectFit: "contain" }}
          />
        </div>
        <div>
          <Typography
            variant="h6"
            sx={{
              fontSize: "19px",
              fontWeight: "600",
              color: "#2f3542",
              marginBottom: "7px"
            }}
          >
            {state?.name}
          </Typography>
          <span style={{ color: "#66BB6A", fontWeight: "600" }}>
            {state?.price?.toFixed(2)} RSD
          </span>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "15px",
              marginTop: "7px",
              maxWidth: "73%"
            }}
          >
            {state?.description}
          </Typography>
        </div>
      </div>

      {renderChildren()}
    </div>
  );
}

export default ProductDetails;
