import { useMemo } from "react";
import { Box } from "@mui/system";
import pic1 from "./images/GILLETTE FUSION 1UP + 1 CART.jpeg";
import pic2 from "./images/GILLETTE FUSION 4 CART.jpeg";
import pic3 from "./images/GILLETTE FUSION 2 CART.jpeg";
import pic4 from "./images/VENUS SENSITIVE DISPO 3+1CT.jpeg";
import pic5 from "./images/GILLETTE MACH 3 1UP + 1 CART.jpeg";
import pic6 from "./images/GILLETTE MACH 3 4 CART.jpeg";
import pic7 from "./images/GILLETTE MACH 3 2 CART.jpeg";
import pic8 from "./images/GILLETTE VENUS RIVIERA 2'S.jpeg";
import pic9 from "./images/Gillette Sensor 3 H+6.jpeg";
import pic10 from "./images/GILLETTE BLUE 3  6+2 'S.jpeg";
import pic11 from "./images/Venus 3 4+2 pcs.jpeg";
import pic12 from "./images/GILLETTE BLUE 3  3+1'S.jpeg";
import pic13 from "./images/BLUE 3 SLALOM COMFORT 3CT.jpeg";
import pic14 from "./images/GILLETTE SIMPLY VENUS 2 4+2'S.jpeg";
import { useMediaQuery, useTheme } from "@mui/material";
import AppCard from "./Card";

function Brijaci() {
  const products = useMemo(
    () => [
      {
        id: 1,
        name: "GILLETTE FUSION 1UP + 1 CART",
        photo: pic1,
        price: 1799.99,
        description:
          "Gillette Fusion muški brijač dolazi sa 5 preciznih oštrica koje u postavljene bliže jedna drugoj (u odnosu na Mach3) za manji osećaj iritacije i izuzetan komfor. Brijač takođe poseduje precizan trimer sa zadnje strane koji je odličan za precizno brijanje krajeva i teško dostupnih mesta kao što su zulufi ili delovi ispod nosa. Jedna dopuna znači i do jedan mesec brijanja. Fusion brijač može da koristi bilo koje Fusion dopune.",
        children: [
          {
            id: 3,
            name: "GILLETTE FUSION 2 CART",
            price: 1599.99,
            photo: pic3,
            description: ""
          },
          {
            id: 2,
            name: "GILLETTE FUSION 4 CART",
            photo: pic2,
            price: 2276.99,
            description: ""
          }
        ]
      },
      {
        id: 4,
        name: "VENUS SENSITIVE DISPO 3+1CT",
        photo: pic4,
        price: 839.99,
        description:
          "Ženski brijač za jednokratnu upotrebu sa 3 oštrice okružen mekim zaštitnim jastučićima • Zaobljena okretna glava brijača stiže i do nepristupačnih delova tela • Traka pokazatelj bledi kada brijanje više nije učinkovito • Posebno izrađena ručka za izvanrednu kontrolu • Zamena oštrica brijača nije potrebna, iskoristite je i bacite",
        children: []
      },
      {
        id: 5,
        name: "GILLETTE MACH 3 1UP + 1 CART",
        photo: pic5,
        price: 1209.99,
        description:
          "Gillette Mach 3 aparat za brijanje je savršen za osetljivu kožu jer se efikasno bori protiv iritacije kože prilikom brijanja. Izuzetno je pogodan i za one sa jačom bradom, zbog svojih jakih oštrica koje pružaju osećaj glatke kože. Prati konture vašeg lica za najbolje Gillette brijanje.",
        children: [
          {
            id: 7,
            name: "GILLETTE MACH 3 2 CART",
            photo: pic7,
            price: 939.99,
            description: ""
          },
          {
            id: 6,
            name: "GILLETTE MACH 3 4 CART",
            photo: pic6,
            price: 1189.99,
            description: ""
          }
        ]
      },
      {
        id: 8,
        name: "GILLETTE VENUS RIVIERA 2'S",
        photo: pic8,
        price: 649.99,
        description:
          "Gillette Venus Riviera brijač predstavlja proizvod iz Gillette linije proizvoda za brijanje koji vam omogućuju glatko i ugodno  brijanje. Koristi se za jednokratnu upotrebu. Pakovanje: 2 komada.",
        children: []
      },
      {
        id: 9,
        name: "Gillette Sensor 3 H+6",
        photo: pic9,
        price: 1099.99,
        description:
          "Gillette sensor 3 muški brijač. Pakovanje sadrži 1 komad + 6 dopuna.",
        children: []
      },
      {
        id: 10,
        name: "GILLETTE BLUE 3  6+2 'S",
        photo: pic10,
        price: 1109.99,
        description:
          "Predstavljamo vam Gillette Blue 3 najbolji jednokratni brijač od Gilletta. Gillette Blue 3 jednokratni brijač sa 3 oštrice pruža 100% bolje ispiranje za glatko brijanja. Pakovanje sadrži 6 jednokratnih brijača.",
        children: []
      },
      {
        id: 11,
        name: "Venus 3 4+2 pcs",
        photo: pic11,
        price: 579.99,
        description:
          "Venus Simply 3 jednokratni brijač sadrži glavu koja se pomera sa tri oštrice. Gumena ergonomska drška prijanja Vašoj ruci, omogućavajući jednostavnije i precizno brijanje. Lubrikanska traka ostavlja nežnu i glatku kožu.",
        children: []
      },
      {
        id: 12,
        name: "GILLETTE BLUE 3  3+1'S",
        photo: pic12,
        price: 625.99,
        description:
          "100% glatko brijanje i bolje ispiranje (u odnosu na Blue II) • brijač za jednokratnu upotrebu, opremljen je poboljšanom trakom za lubrikaciju za izuzetnu udobnost (u poređenju sa Blue II Plus) • Glava koja se okreće prilagođava se obrisima vašeg lica • Posebna Soft Ultragrip ručica za izvanrednu kontrolu • Možete se obrijati do 5 puta jednom brijačem",
        children: []
      },
      {
        id: 13,
        name: "BLUE 3 SLALOM COMFORT 3CT",
        photo: pic13,
        price: 309.99,
        description:
          "Gillette Blue3 COMFORT SLALOM brijač za jednokratnu upotrebu ima 3 hromirane oštrice koje se prilagođavaju koži i glavu koja se okreće za 40° koja prati konture lica. Gillette Blue3 COMFORT SLALOM poboljšani Lubrastrip sistem sa tehnologijom Comfortgel koja se aktivira pod vodom pruža vam maksimalnu udobnost. Brijač ima gumenu ručku za bolju kontrolu i sečiva koja se lako ispiraju.",
        children: []
      },
      {
        id: 14,
        name: "GILLETTE SIMPLY VENUS 2 4+2'S",
        photo: pic14,
        price: 147.99,
        description:
          "Gillette Simply Venus brijači imaju 2 oštrice i Moisture Rich hidrantnu trakicu za lakše klizanje brijača. U pakovanju se nalaze 6 jednokratnih brijača.",
        children: []
      }
    ],
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ marginTop: "10px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "flex-start"
          }}
        >
          {products.map((product) => (
            <AppCard product={product} key={product.id} />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default Brijaci;
