import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { NavLink, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

export default function AppBreadcrumbs() {
  const { pathname } = useLocation();
  const pages = pathname.split("/").filter(Boolean);
  const decodedPages = pages.map((page) => decodeURI(page));

  const getToLink = (index: number) => {
    const slicedPages = decodedPages.slice(0, index + 1);
    const joinedPagesLink = slicedPages.join("/");
    return `/${joinedPagesLink}`;
  };

  let inactiveStyle: React.CSSProperties = {
    textDecoration: "none",
    backgroundColor: "rgb(245 245 245 / 32%)",
    height: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    paddingRight: "12px",
    paddingLeft: "12px",
    borderRadius: "15px"
  };

  let activeStyle: React.CSSProperties = {
    textDecoration: "none",
    backgroundColor: "rgb(245, 245, 245)",
    height: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    paddingRight: "12px",
    paddingLeft: "12px",
    borderRadius: "15px"
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <NavLink
        to="/"
        style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
        className="navlink"
      >
        <HomeIcon
          sx={{
            width: "20px",
            height: "20px",
            marginRight: "4px",
            color: "rgb(97, 97, 97)"
          }}
        />
        Home
      </NavLink>
      {decodedPages.map(
        (link, i) =>
          link && (
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
              to={getToLink(i)}
              key={`${link}/${i}`}
              className={`navlink ${i === pages.length - 1 ? "disabled" : ""}`}
              onClick={(e) => {
                const isLast = i === pages.length - 1;
                if (isLast) e.preventDefault();
              }}
            >
              {capitalizeFirstLetter(link)}
            </NavLink>
          )
      )}
    </Breadcrumbs>
  );
}
